import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA/ContactCTA';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import parseHtml from '../helpers/parseHtml';
import SEO from '../components/seo';
import usePageRehydrate from '../hooks/usePageRehydrate';
import NavButton from '../components/atoms/NavButton';

const PageTemplate = ({ data: { page, globalPage } }) => {
  const pageData = usePageRehydrate(page || globalPage);
  return (
    <>
      <SEO
        id={pageData.foreignId}
        title={pageData.metaTitleFormatted}
        description={pageData.metaDescription}
        ogImage={pageData.ogImage}
        metaTags={pageData.metaTags}
        slug={pageData.slug}
      />
      <TwoColumnLayout
        title={pageData.title}
        col1={parseHtml(pageData.content)}
        col2={<ContactCTA mt="40px" NavButtonComponent={NavButton} />}
      />
    </>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
    globalPage: PropTypes.object,
  }).isRequired,
};
PageTemplate.defaultProps = {};

export default PageTemplate;

export const query = graphql`
  query getPage($slug: String!) {
    page(slug: {eq: $slug}) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      metaTags
      menuTitle
      id
      foreignId
      content
      className
    }
    globalPage(slug: {eq: $slug}) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      menuTitle
      id
      foreignId
      content
      className
    }
}`;
