import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box, { BoxProps } from '../../atoms/Box/Box';
import Title from '../../atoms/Title/Title';
import Text from '../../atoms/Text/Text';
import Button from '../../atoms/Button/Button';
import Flex from '../../atoms/Flex/Flex';

const CTA = styled(Box)`
  width: 100%;
  background: ${themeGet('colors.gold.400')};
  transform: matrix(1, 0.03, -0.02, 1, 0, 0);
`;

const Body = styled(Box)`
  transform: matrix(1, -0.03, 0.02, 1, 0, 0);
  padding: 35px;
`;

const Background = styled(Box)`
  height: 100%;
  background: ${themeGet('colors.grayscale.300')};
  transform: matrix(1, -0.03, -0.02, -1, 0, 0);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
`;

const ContactCTA = ({
  NavButtonComponent,
  ...props
}: {
  NavButtonComponent: React.ReactNode;
} & BoxProps) => (
  <Box position="relative" {...props}>
    <Background />
    <CTA>
      <Body>
        <Flex flexDirection="column" alignItems="center">
          <Title as="h3" size="h4" textAlign="center">
            Got a Question?
          </Title>
          <Text fontSize="l" textAlign="center">
            Our support team would love to help! Click to ask a question and{' '}
            we&rsquo;ll get back to you as soon as possible.
          </Text>
          <Button
            kind="cta"
            to="/contact-us/"
            NavComponent={NavButtonComponent}
          >
            Ask Now
          </Button>
        </Flex>
      </Body>
    </CTA>
  </Box>
);

export default ContactCTA;
